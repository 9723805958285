import LazyLoad from "vanilla-lazyload";

$(document).ready(function () {

    var lazyLoadInstance = new LazyLoad({});
    window.addEventListener("LazyLoad::Initialized", function (event) {
        window.lazyLoadInstance = event.detail.instance;
    }, false);

});

/*
SLIDERS
 */
import Swiper, {Autoplay, Controller, EffectFade, Lazy, Navigation, Pagination, Thumbs} from 'swiper';
import {gsap} from 'gsap';
import {ScrollTrigger} from "gsap/ScrollTrigger";

Swiper.use([Navigation, Pagination, Thumbs, Autoplay, EffectFade, Lazy, Controller]);

/* Slider hero */
let nbSlideMaison = $('#slider-maison .swiper-slide').length;
const swiperMaison = new Swiper('#slider-maison', {
    loop: true,
    autoplay: true,
    pagination: {
        el: ".swiper-maison_pagination",
        type: "progressbar",
    },
    navigation: {
        nextEl: '.swiper-maison_next',
        prevEl: '.swiper-maison_prev',
    },
    breakpoints: {
        680: {
            slidesPerView: 1.5,
            spaceBetween: 80,
        }
    },
    on: {
        init: function (sw) {
            let total = document.querySelector('.slide-count-total');
            total.innerHTML = nbSlideMaison;

            let width = $('#slider-maison .swiper-slide img').width()
            $('.slider-maison-controls').width(width)
        },
        slideChange: function (sw) {
            let index = $('.slide-count-index')
            index.text(sw.realIndex + 1);
        },
        resize: function (sw) {
            let total = document.querySelector('.slide-count-total');
            total.innerHTML = nbSlideMaison
        }
    }
})

$(window).on('resize', function () {
    let width = $('#slider-maison .swiper-slide img').width()
    $('.slider-maison-controls').width(width)
})

const swiperGammes = new Swiper('#slider-gammes', {
    loop: true,
    centeredSlides: true,
    slideToClickedSlide: true,
    navigation: {
        nextEl: '.swiper-gammes_next',
        prevEl: '.swiper-gammes_prev',
    },
    breakpoints: {
        680: {
            slidesPerView: 2,
            spaceBetween: 50,
        },
        1280: {
            allowTouchMove: false,
            slidesPerView: 4,
            spaceBetween: 50,
        }
    },
    on: {
        init: function (sw) {
            let active = $('.swiper-slide-active')
            active.prev().addClass('swiper-slide-before')
            active.prev().prev().addClass('swiper-slide-before swiper-slide-before-2')
            active.next().addClass('swiper-slide-after')
            active.next().next().addClass('swiper-slide-after swiper-slide-after-2')
        },
        slideChange: function () {
            let activeIndex = this.activeIndex;
            let realIndex = this.slides.eq(activeIndex).attr('data-swiper-slide-index');
            $('.swiper-slide').removeClass('swiper-slide-before swiper-slide-before-2 swiper-slide-after swiper-slide-after-2')
            $('.swiper-slide[data-swiper-slide-index="' + realIndex + '"]').prev().addClass('swiper-slide-before')
            $('.swiper-slide[data-swiper-slide-index="' + realIndex + '"]').prev().prev().addClass('swiper-slide-before swiper-slide-before-2')
            $('.swiper-slide[data-swiper-slide-index="' + realIndex + '"]').next().addClass('swiper-slide-after')
            $('.swiper-slide[data-swiper-slide-index="' + realIndex + '"]').next().next().addClass('swiper-slide-after swiper-slide-after-2')

            /* Change info gamme */
            let nom = $('.swiper-slide[data-swiper-slide-index="' + realIndex + '"]').data('gamme')
            $('#nom-gamme').text(nom)

            let lien = $('.swiper-slide[data-swiper-slide-index="' + realIndex + '"]').find('a').attr("href")
            $('#lien-gamme').attr("href", lien)

        },
        resize: function (sw) {
            let active = $('.swiper-slide-active')
            $('.swiper-slide').removeClass('swiper-slide-before swiper-slide-before-2 swiper-slide-after swiper-slide-after-2')
            active.prev().addClass('swiper-slide-before')
            active.prev().prev().addClass('swiper-slide-before swiper-slide-before-2')
            active.next().addClass('swiper-slide-after')
            active.next().next().addClass('swiper-slide-after swiper-slide-after-2')
        }
    }
})

gsap.registerPlugin(ScrollTrigger);

function animateFrom(elem, direction) {
    direction = direction || 1;
    let x = 0,
        y = direction * 100;
    if (elem.classList.contains("fromLeft")) {
        x = -100;
        y = 0;
    } else if (elem.classList.contains("fromRight")) {
        x = 100;
        y = 0;
    }
    elem.style.transform = "translate(" + x + "px, " + y + "px)";
    elem.style.opacity = "0";
    gsap.fromTo(elem, {x: x, y: y, autoAlpha: 0}, {
        duration: 1.25,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto"
    });
}

function hide(elem) {
    gsap.set(elem, {autoAlpha: 0});
}

gsap.utils.toArray("#reassurance h2 > span").forEach(function (elem) {
    hide(elem);
    ScrollTrigger.create({
        trigger: elem,
        start: 'top bottom',
        once: true,
        onEnter: function () {
            animateFrom(elem)
        },
    });
});

gsap.utils.toArray("#home-maison > div:first-of-type").forEach(function (elem) {
    hide(elem);
    ScrollTrigger.create({
        trigger: elem,
        start: 'top bottom',
        once: true,
        onEnter: function () {
            animateFrom(elem)
        },
    });
});
